.MyStaffs {
  height: 85%;

  h1 {
    font-family: 'Syne';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    // text-align: center;

    color: #000000;
  }

  .no-project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;

    a {
      /* Auto layout */

      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      width: 165px;
      height: 53px;

      background: #9fa1cf;
      border: 2px solid #000aff;
      border-radius: 100px;

      // text

      font-family: 'Syne';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      // text-align: center;

      color: #ffffff;
    }
  }
}

.projectCard {
  display: flex;
  justify-content: space-between;
  padding: 0 35px;
  align-items: center;
  margin: 20px 0;
  // width: 1093px;
  height: 112px;

  background: #ffffff;
  border-radius: 10px;
  .ProgressBarComponent {
    // border: solid;
    width: 50px;
    height: 50px;
    h2 {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      /* identical to box height */

      color: #111111;
    }
  }
}

.FilterScrollComponent {
  // border: solid;
  display: flex;
  justify-items: center;
  align-items: center;
  // height: 90px;
  // margin-bottom: 30px;
  // text
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;

  .filter-container {
    display: flex;
    padding: 10px;
    gap: 10px;
    width: 100%;
    margin: 0 39px 0 10px;
    button {
      // border: solid;

      /* Auto layout */

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 12px;
      gap: 12px;

      // width: 107px;
      height: 40px;

      /* White/Silver */

      background: #f1f1f1;
      border-radius: 50px;

      // text
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      white-space: nowrap;
      color: rgba(17, 17, 17, 0.4);
    }
    button.active {
      /* Cool Gradient */

      background: linear-gradient(
        264.78deg,
        #ff693f 3.98%,
        #eb00b9 53.49%,
        #5f38ff 100%
      );
      color: rgba(255, 255, 255, 0.7);
    }
    &::-webkit-scrollbar {
      height: 10px;
      width: 12px; /* Width of the entire scrollbar */
      background-color: transparent; /* Background color of the scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      height: 10px;
      border-radius: 10px; /* Rounded corners of the scrollbar thumb */
      background-color: var(
        --primary-Blue
      ); /* Background color of the scrollbar thumb */
    }
  }
}
